require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/menu.css');
require('jquery-ui/themes/base/autocomplete.css');
require('jquery-ui/themes/base/theme.css');
var autocomplete = require('jquery-ui/ui/widgets/autocomplete');

$( document ).on('turbolinks:load', function() {
  $( "input#user_email" ).autocomplete({
    source: "/ldap/search",
    minLength: 2,
  });
});
